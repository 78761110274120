/* Styles pour PC */
.project_detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    >* {
        margin: 2%;
    }
}

.project_detail >* :hover{
    
    .project_detail{
        background-color: aquamarine;
    }

}