.aboutMe {
    min-height: 100vh;
    height: auto;
    max-height: auto;

    width: 100%;
    min-width: 35vh;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-left: 15%;
    padding-right: 15%;

    background-color: rgba(59, 105, 183, 0.829);

    > section {
        min-height: 50%;
        width: 50%;
        min-width: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
    }
}

.presentation{
    min-height: 90vh;

    >*{
        margin: 2%;
    }
    h1 {
        font-size: 4em;
    }
}

.description {
    font-size: 1.2em;
    align-items: center;
    img{
        height: 20vh;
        width: 20vh;
    }
}

/*** Code css flèche ***/
.arrow {
    position: absolute;
    top: 93%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

@media (max-width: 800px) {
    .arrow span {
        width: 0.5;
        height: 0.5vh;
    }
}

.arrow span {
    display: block;
    width: 1vw;
    height: 1vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    animation: animate-arrow 3s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate-arrow {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}